@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&display=swap);
.ant-empty {
    margin: 25px auto !important;
}

.donate-component {
    background: #fff;
    max-width: 1000px;
    margin: 0 auto;
    text-align: center;
    margin-top: 30px;

    box-shadow: -1px 6px 82px -15px rgba(0,0,0,0.55);
    -webkit-box-shadow: -1px 6px 82px -15px rgba(0,0,0,0.55);
    -moz-box-shadow: -1px 6px 82px -15px rgba(0,0,0,0.55);
}

.donate-welcome {
    background: #45c595;
    padding: 80px 25px 25px;
}

.donate-welcome .bg{
    opacity: 0.4;
    background-image: url(/static/media/background.930cf215.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    left: 0;
    top: 0;
    z-index: 0;
}

.donate-welcome > * {
    position: relative;
    z-index: 1;
}

.donate-welcome h2 {
    color: #ffffff;
    z-index: 1;
}

.donate-welcome p {
    margin-bottom: 40px;
    color: #ffffff;
}

.donate-welcome img {
    width: 100px;
    margin-bottom: 35px;
}

.donate-welcome button {
    width: 100%;
    margin-bottom: 60px;
    background-color: transparent;
    border: 1px solid #FFFFFF;
}

.donate-welcome button:hover {
    background-color: #70d4ae !important;
    border-color: #FFFFFF !important;
}
  


.donate-content .user-info {
    padding: 25px;
    border-bottom: 1px solid #e4e4e4;
}

.donate-content .user-info p {
    margin: 0;
    color: #409780;
}

.donate-content .user-info .copy {
    padding-left: 5px;
    color: #409780;
}

.donate-content .evm-info {
    padding: 25px;
    border-bottom: 1px solid #e4e4e4;
}

.donate-content .evm-info .ant-card {
    margin: 0;
    text-align: left;
    border-color: #e4e4e4;
    margin-bottom: 10px;
}

.donate-content .evm-info .ant-card:last-child {
    margin-bottom: 0;
}

.donate-content .evm-info .evm-asset::after {
    content: 'EVM';
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 12px;
    font-weight: 600;
    background-color: #68b49b;
    padding: 2px 8px;
    color: #ffffff;
}

@media (max-width:800px) {
    .donate-content .evm-info .evm-asset li {
        width: 100% !important;
    }
}

.donate-content .evm-info .native-asset::after {
    content: 'Native';
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 12px;
    font-weight: 600;
    background-color: #4841ad;
    padding: 2px 8px;
    color: #ffffff;
}

@media (max-width:800px) {
    .donate-content .evm-info .native-asset li {
        width: 100% !important;
    }
}

.donate-content .evm-info .assets {
    text-align: left;
    font-weight: 700;
    text-transform: uppercase;
    color: #757575;
    font-size: 12px;

}

.donate-content .evm-info .ant-card-meta-title {
    font-weight: 800;
}

.donate-content .evm-info .ant-card-meta-description {
    color: #409780;
}

.donate-content .evm-info .ant-card-meta-description .copy {
    padding-left: 5px;
}



.donate-content .actions-info {
    padding: 25px;
    min-height: 560px;
}

.donate-content .ant-radio-group {
    padding-bottom: 10px;
}

.donate-content .pagination {
    margin: 0 auto;
    padding-top: 20px;
}

.donate-content .actions-info .actions {
    text-align: left;
    font-weight: 700;
    text-transform: uppercase;
    color: #757575;
    font-size: 12px;
}

.evm-info .ant-card-actions > li {
    margin: 0 0;
}

.evm-info .ant-card-actions > li > span > * {
    padding: 10px 0;
    width: 100%;
    display: block
}

.evm-info .ant-card-actions > li > span > .disabled {
    color: #c9c9ca;
    cursor: not-allowed;
}

.evm-info .ant-card-actions > li > span > .disabled:hover {
    color: #c9c9ca;
}

.actions-item {
    width: 100%;
    border-bottom: 1px solid #e4e4e4;
    margin-top: 5px;
}

.actions-item .address {
    font-weight: 600;
    font-size: 12px;
    line-height: 30px;
}

.actions-item .hash {
    font-weight: 600;
    font-size: 12px;
    line-height: 30px;
}

.actions-item .badge {
    color: #673ab7;
    font-size: 18px;
}

.actions-item .badge .anticon-dollar-circle {
    color: #2196f3;
}

.actions-item .value {
    font-weight: 600;
    font-size: 12px;
    line-height: 32px;
}
.demo h3 {
    margin-bottom: 20px;
}

.title-block {
    padding-top: 60px;
    padding-bottom: 20px;
    text-align: center;
    color: white;
    font-weight: 600;
    font-size: 17px;
}

@media (max-width: 950px) {
    .title-block {
        padding-top: 20px;
    }
}

.title-block h1{
    color: white !important;
    margin-bottom: 25px;
}

.title-block h1 b{
    color: #0f2b20 !important;
}

.variable-value > div {
    display: block;
    word-break: break-all;
}

.object-key-val {
    padding: 20px;
}

.ant-checkbox-group {
    margin-bottom: 15px !important;
}

.ant-checkbox-group > label{
    display: flex;
}

.try-demo-section {
    position: relative;
    background: #fff;
    padding: 60px 35px;
    width: 400px;
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
    margin-top: 30px;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;

    box-shadow: -1px 6px 82px -15px rgba(0,0,0,0.55);
    -webkit-box-shadow: -1px 6px 82px -15px rgba(0,0,0,0.55);
    -moz-box-shadow: -1px 6px 82px -15px rgba(0,0,0,0.55);
}

.try-demo-section img {
    width: 80px;
    opacity: 0.8;
    border-radius: 10px;
    margin-bottom: 25px;
}

.try-demo-section h4 {
    margin-bottom: 20px;
}

.try-demo-section p {
    margin-bottom: 0;
}

.try-demo-section button {
    margin-top: 10px;
    margin-right: 0;
    margin-bottom: 20px;
}

.ant-spin-spinning.loading-spin {
    margin: 40px auto;
    display: block;
}

.loading-spin .ant-spin-dot-item {
    background-color:#ffffff;
}
body {
  background-color: #fafbfc;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #292d34;
}

h1, h2, h3 {
  font-weight: 600;

}

h1 {
  font-weight: 700;
  margin-bottom: 10px;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.ant-layout {
    background-color: #fff;
}

.ant-checkbox-wrapper { color:#292d34 }

h3, .ant-list-items, .ant-message-custom-content span {
  word-wrap: break-word;
}

.ant-message-notice-content {
  max-width: 90%;
}
.ant-message-custom-content span {
  word-wrap: break-word;
}

/*HEADER*/

.ant-layout-header {
    background-color: #fff;
    line-height: 40px;
    padding: 0 72px;
    height: 40px;
    margin-top: 30px;
}

@media (max-width: 950px) {
  .ant-layout-header {
    margin-top: 20px;
  }
}


.ant-layout-header h1 {
    color: #ffffff;
    letter-spacing: 1px;
}

.background-component {
  position: absolute;
  width: 100%;
  min-width: 1200px;
  margin: 0 auto;
  background-color: #fafbfc;
}

/*CONTENT*/

#root {
  width: 100%;
  padding-bottom: 100px;
  min-height: 100%;
  overflow: hidden;
  position: relative;
}

.ant-layout-content {
    padding: 0 72px;
    z-index: 100;
}

.ant-layout {
  background-color: #fafbfc;
}

.ant-layout-content h1 {
  font-size: 32px;
  font-weight: 800;
  line-height: 1.3;
  color:#000000;
}

.landing {
  margin-top: 15px;
}

.left-section {
  width: 100%;
  min-height: 500px;
}

/*BUTTON*/

.ant-message-success .anticon {
  color: #71b9a2;
}

.ant-btn-primary {
  background-color: #71b9a2;
  border-radius: 0;
  border: 0;
  margin-right: 10px;
  margin-bottom: 10px;
}

.ant-btn-primary:hover {
  opacity: 0.8;
  background-color: #95d6bb;
}

.ant-btn-primary:active, .ant-btn-primary:focus {
  background-color: #95d6bb;
}

.ant-btn > .anticon + span, .ant-btn > span + .anticon {
  margin-left: 12px;
}

.right-section {
  display: none;
  width: 70%;
  position: absolute;
  right: 0;
  top: 20%;
  text-align: center;
  opacity: 0.8;
}

.right-section img {
  width: 50%;
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.left-nav h1 {
  display: inline-block;
  margin-right: 40px;
  margin-bottom: 0;
}

.left-nav .ant-btn {
  background-color: #6ebda3;
  vertical-align: top;
  border: 1px solid;
}

.left-nav .ant-btn:hover {
  background-color: #95d6bb;
}

@media (max-width: 500px) {
  .left-nav .ant-btn {
    display: block;
  }
}


.header-actions {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}

.header-actions .ant-btn { margin-left: 10px !important; }

.header-actions .ant-dropdown-trigger { 
  margin-left: 20px !important;
  display: inline-block;
}

.header-actions .ant-avatar {
  color: #fff;
  background: #39967a;
  width: 36px;
  height: 36px;
}

.header-actions .account-name {
  color: #fff;
  padding-left: 10px;
  font-weight: 600;
}

.ant-dropdown-menu-item {
  text-align: center !important;
}

.header-components-wrapper {
  position: relative;
}

.header-components-wrapper .ant-btn {
  margin: 0;
}

@media (max-width: 1300px) {
  .right-section {
    width: 50%;
  }

  .right-section img {
    width: 85%;
  }
}

@media (max-width: 950px) {
  .right-section {
    display: none;
  }

  .ant-layout-header {
    padding: 0 20px;
    line-height: 40px;
    height: 85px;
  }

  .ant-layout-content {
    padding: 0 20px;
  }

  .left-section h1 {
    font-size: 32px;
  }

  .ant-list-item {
    display: block;
  }
  
  .ant-list-item > * {
    margin-top: 15px;
  }

  h3 {
    font-size: 16px;
  }
}

@-webkit-keyframes color {
  0%   { background: #c3eada; }
  50%  { background: rgb(255, 255, 255); }
  100% { background: #c3eada; }
}

@keyframes color {
  0%   { background: #c3eada; }
  50%  { background: rgb(255, 255, 255); }
  100% { background: #c3eada; }
}

.background-action {
  background: #33CCCC; /* Fallback */
  -webkit-animation: color 1.5s infinite linear;
  animation: color 1.5s infinite linear;
}


.demo-logo {
  text-align: center;
  display: inline-block;
  width: 70px;
  height: 70px;
  line-height: 70px;
  position: relative;
  color: #ffffff;
  font-size: 50px;
  background: #7be0c0;
  border-radius: 15px;
  overflow: hidden;
  font-weight: 600;
  margin: 0 auto;
  margin-bottom: 35px;
}

.demo-logo::before {
    content: "D";
    opacity: 0.4;
    background-image: url(/static/media/background.930cf215.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    left: 0;
    top: 0;
    z-index: 0;
}

.border {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
}

.progress {
  background: #f44336;
  height: 5px;
  -webkit-animation: width 6s;
  animation: width 6s;
}

@-webkit-keyframes width {
  from {width: 0%}
  to {width: 100%}
}

@keyframes width {
  from {width: 0%}
  to {width: 100%}
}
