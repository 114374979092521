@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&display=swap');

body {
  background-color: #fafbfc;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #292d34;
}

h1, h2, h3 {
  font-weight: 600;

}

h1 {
  font-weight: 700;
  margin-bottom: 10px;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.ant-layout {
    background-color: #fff;
}

.ant-checkbox-wrapper { color:#292d34 }

h3, .ant-list-items, .ant-message-custom-content span {
  word-wrap: break-word;
}

.ant-message-notice-content {
  max-width: 90%;
}
.ant-message-custom-content span {
  word-wrap: break-word;
}

/*HEADER*/

.ant-layout-header {
    background-color: #fff;
    line-height: 40px;
    padding: 0 72px;
    height: 40px;
    margin-top: 30px;
}

@media (max-width: 950px) {
  .ant-layout-header {
    margin-top: 20px;
  }
}


.ant-layout-header h1 {
    color: #ffffff;
    letter-spacing: 1px;
}

.background-component {
  position: absolute;
  width: 100%;
  min-width: 1200px;
  margin: 0 auto;
  background-color: #fafbfc;
}

/*CONTENT*/

#root {
  width: 100%;
  padding-bottom: 100px;
  min-height: 100%;
  overflow: hidden;
  position: relative;
}

.ant-layout-content {
    padding: 0 72px;
    z-index: 100;
}

.ant-layout {
  background-color: #fafbfc;
}

.ant-layout-content h1 {
  font-size: 32px;
  font-weight: 800;
  line-height: 1.3;
  color:#000000;
}

.landing {
  margin-top: 15px;
}

.left-section {
  width: 100%;
  min-height: 500px;
}

/*BUTTON*/

.ant-message-success .anticon {
  color: #71b9a2;
}

.ant-btn-primary {
  background-color: #71b9a2;
  border-radius: 0;
  border: 0;
  margin-right: 10px;
  margin-bottom: 10px;
}

.ant-btn-primary:hover {
  opacity: 0.8;
  background-color: #95d6bb;
}

.ant-btn-primary:active, .ant-btn-primary:focus {
  background-color: #95d6bb;
}

.ant-btn > .anticon + span, .ant-btn > span + .anticon {
  margin-left: 12px;
}

.right-section {
  display: none;
  width: 70%;
  position: absolute;
  right: 0;
  top: 20%;
  text-align: center;
  opacity: 0.8;
}

.right-section img {
  width: 50%;
  -moz-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.left-nav h1 {
  display: inline-block;
  margin-right: 40px;
  margin-bottom: 0;
}

.left-nav .ant-btn {
  background-color: #6ebda3;
  vertical-align: top;
  border: 1px solid;
}

.left-nav .ant-btn:hover {
  background-color: #95d6bb;
}

@media (max-width: 500px) {
  .left-nav .ant-btn {
    display: block;
  }
}


.header-actions {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}

.header-actions .ant-btn { margin-left: 10px !important; }

.header-actions .ant-dropdown-trigger { 
  margin-left: 20px !important;
  display: inline-block;
}

.header-actions .ant-avatar {
  color: #fff;
  background: #39967a;
  width: 36px;
  height: 36px;
}

.header-actions .account-name {
  color: #fff;
  padding-left: 10px;
  font-weight: 600;
}

.ant-dropdown-menu-item {
  text-align: center !important;
}

.header-components-wrapper {
  position: relative;
}

.header-components-wrapper .ant-btn {
  margin: 0;
}

@media (max-width: 1300px) {
  .right-section {
    width: 50%;
  }

  .right-section img {
    width: 85%;
  }
}

@media (max-width: 950px) {
  .right-section {
    display: none;
  }

  .ant-layout-header {
    padding: 0 20px;
    line-height: 40px;
    height: 85px;
  }

  .ant-layout-content {
    padding: 0 20px;
  }

  .left-section h1 {
    font-size: 32px;
  }

  .ant-list-item {
    display: block;
  }
  
  .ant-list-item > * {
    margin-top: 15px;
  }

  h3 {
    font-size: 16px;
  }
}

@keyframes color {
  0%   { background: #c3eada; }
  50%  { background: rgb(255, 255, 255); }
  100% { background: #c3eada; }
}

.background-action {
  background: #33CCCC; /* Fallback */
  -webkit-animation: color 1.5s infinite linear;
  -moz-animation: color 1.5s infinite linear;
  -o-animation: color 1.5s infinite linear;
  animation: color 1.5s infinite linear;
}


.demo-logo {
  text-align: center;
  display: inline-block;
  width: 70px;
  height: 70px;
  line-height: 70px;
  position: relative;
  color: #ffffff;
  font-size: 50px;
  background: #7be0c0;
  border-radius: 15px;
  overflow: hidden;
  font-weight: 600;
  margin: 0 auto;
  margin-bottom: 35px;
}

.demo-logo::before {
    content: "D";
    opacity: 0.4;
    background-image: url('./assets/background.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    left: 0;
    top: 0;
    z-index: 0;
}

.border {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
}

.progress {
  background: #f44336;
  height: 5px;
  -moz-animation: width 6s;
  -webkit-animation: width 6s;
  -o-animation: width 6s;
  animation: width 6s;
}

@keyframes width {
  from {width: 0%}
  to {width: 100%}
}